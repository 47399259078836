import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { linkResolver } from "../components/_helpers/linkResolver"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CaseStudy = ({ data }) => {
  if (!data || !data.data) return null
  const case_study_data = data.data
  if (!case_study_data.customer) return null

  return (
    <div className="case-study" id={data.uid} data-sal="slide-up" data-sal-delay="300">
      <div className="col asset">
        {case_study_data.body.length > 0 &&
          <Link to={linkResolver(data)}>
            <Img fluid={case_study_data.cover_image.fluid} alt={case_study_data.customer.document.data.customer_name.text} />
          </Link>
        }

        {case_study_data.body.length === 0 &&
          <Img fluid={case_study_data.cover_image.fluid} alt={case_study_data.customer.document.data.customer_name.text} />
        }
      </div>

      <div className="col text">
        <h5>{case_study_data.headline.text}</h5>
        <div className="body" dangerouslySetInnerHTML={{ __html: case_study_data.excerpt?.html }}></div>
        
        <div className="customer-info">
          <div className="logo">
            <Img
              fluid={case_study_data.customer.document.data.customer_logo.fluid}
              imgStyle={{ objectFit: "contain", objectPosition: "0 0" }}
              alt={case_study_data.customer.document.data.customer_name.text}
            />
          </div>
          {case_study_data.body.length > 0 &&
            <div className="link">
              <Link to={linkResolver(data)} className="button">Read More</Link>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const CustomersPage = () => {
  let data = useStaticQuery(graphql`
    query CustomersPageQuery {
      prismicCustomersPage {
        data {
          hero_title {
            text
            html
          }
          hero_subtitle {
            html
            text
          }
          logos {
            customer {
              document {
                ... on PrismicCustomer {
                  id
                  data {
                    customer_logo {
                      fluid(maxHeight: 50) {
                        ...GatsbyPrismicImageFluid
                      }
                    }
                    customer_name {
                      html
                      text
                    }
                  }
                }
              }
            }
          }
          case_studies_headline {
            text
            html
          }
          case_studies {
            case_study {
              document {
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    customer {
                      document {
                        ... on PrismicCustomer {
                          id
                          data {
                            customer_logo {
                              fluid(maxHeight: 50) {
                                ...GatsbyPrismicImageFluid_noBase64
                              }
                            }
                            customer_name {
                              text
                              html
                            }
                          }
                        }
                      }
                    }
                    cover_image {
                      fluid(maxHeight: 1000) {
                        ...GatsbyPrismicImageFluid_noBase64
                      }
                    }
                    excerpt {
                      html
                      text
                    }
                    headline {
                      text
                    }
                    body {
                      __typename
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).prismicCustomersPage.data
  if (!data) return null

  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicCustomersPage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicCustomersPage.data
  }

  return (
    <Layout mainClass="customers-page">
      <SEO title="Customers" />

      <section className="customers-hero hero">
        <div className="inner">
          <h3 data-sal="slide-up" data-sal-delay="300">{data.hero_title.text}</h3>
          <div data-sal="slide-up" data-sal-delay="600" dangerouslySetInnerHTML={{__html: data.hero_subtitle.html}}></div>

          <div className="logos">
            {data.logos.map((logo, ind) => {
              if (!logo.customer.document) return null
              
              return <div className="logo" key={logo.customer.document.id} data-sal="slide-up" data-sal-delay={`${150 + (ind++ * 50)}`}>
                <Img loading="eager" fluid={logo.customer.document.data.customer_logo.fluid} imgStyle={{ objectFit: "contain" }} alt={logo.customer.document.data.customer_name.text} />
              </div>
            })}
          </div>
        </div>
      </section>

      <section className="customers-case-studies">
        <div className="inner">
          <h3 data-sal="slide-up" data-sal-delay="300">{data.case_studies_headline.text}</h3>

          {data.case_studies.map((case_study, ind) => {
            return <CaseStudy data={case_study.case_study.document} key={ind} />
          })}
        </div>
      </section>
    </Layout>
  )
}

export default CustomersPage
